
.vmSettingsPage{
    margin-bottom: 80px !important;
}

.vmSettingsPage label{
    font-size: 16px !important;
}

.vmEnable{
    text-align: center;
    display: block;
}

.vmSettingsPage .custom-control {
    padding-left: 0;

}

.vmSettingsPage .custom-checkbox input{
    margin-right: 5px;
}

.vmSettingsPage .link-btns {
    padding-left: 0;
    padding-right: 0;
}

.vol-span{
    padding: 6px;
    border: 2px solid lightgrey;
    border-radius: 999px;
    font-size: 16px;
}

.floating-footer{
    position: fixed;
    bottom: 0px;
    left: 0;
    padding: 10px;
    text-align: center;
    width: 98%;
    background: #f4f7fa;
}

.floating-footer button{
    margin-left: 10px;
    width: 120px;
}

.bg-full-custom-web-tab{
    background-image: linear-gradient(to right, rgb(22, 34, 42), rgb(58, 96, 115));
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    /* align-content: center; */
    justify-content: center;
    height: 100vh;
    
}
.setting-image img{
    width: 60px;
    cursor: pointer;
}
.setting-image h5{
    color: white;
    font-size: 16px;
    margin-top: 10px;
    font-weight: 600;
}

.setting-image{
    text-align: center;
    text-align: -webkit-center;
}

.img-icons{
    width: 24px;
    margin: 0px 10px;
}



.CustomMainIndexApp .custom-data-table-icon {
    font-size: 24px;
    margin: 0px 10px;
}

.greeting-play-pause svg {
    margin-top: 6px !important;
}

.rhap_progress-section{
    display: none;
}
.rhap_additional-controls{
    display: none;
}
.rhap_rewind-button{
    display: none;
}

.rhap_forward-button{
    display: none;
}

.rhap_volume-button{
    display: none;
}

.rhap_play-pause-button{
    display: none;
}

.rhap_container {
    float: left;
}
.voice-message-icons{
    border: 1px solid;
    border-radius: 55%;
    padding: 0px 1px 0px 1px;
}

.voice-messages-table{
    color: black;
}

.voice-messages-table th{
    border-top: none !important;
}

.voice-message-text span{

    margin-top: -1px;
    padding-top: 0px;
    height: 12;
    color: #999;
    word-wrap: break-word !important;
    border-left: 5px solid #e3e3e3;
    padding-left: 10px;
}
.voice-message-text{
    border: none !important;
}
.voice-messages-table table {
    table-layout:auto;
    width: 100%;
}
.voice-messages-table table td {
    white-space: normal;
    word-wrap: break-word;
}

.voice-message-layout{
    width: 100%;
    padding: 5px;
    margin-left: 0px;
    margin-top: 80px;
}
.nonecreated {
    top: 50px;
    text-align: center;
    color: gray;
    position: relative;
}
.nonecreated h3{
    color: gray;
}

.voice-mail-btn-div{
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
}

.voice-mail-row2{
    display: grid;
    grid-template-columns: auto auto;
}


.voice-mail-borderNone td{
    border: none !important;
}

.voice-mail-borderNone{
    border: none;
    background: transparent !important;
}

.voice-mail-btn-td{
    padding-top: 1em !important;
    background: transparent !important;
    border-top: 1px solid #dee2e6 !important;
}

.voice-mail-displayInfo span{
    font-weight: 700;
}

.voice-messages-table tr{
    background: transparent !important;
    border: none;
}
.voice-messages-table td{
    background: transparent !important;
    border: none;
}

.voice-mail-btn-td{
    padding-top: 1em !important;
    background: transparent !important;
    border-top: 1px solid #dee2e6 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}


.export-button a{
    color: white;
}
.btn-primary-turbored{
    color: #fff;
    background-color: rgb(108, 194, 74);
    border-color: rgb(108, 194, 74);
}

.btn-primary-turbored:hover{
    color: #fff;
}

.btn-secondary-turbored{
    color: #fff;
    background-color: #58595B;
    border-color: #58595B;
}

.btn-secondary-turbored:hover{
    color: #fff;
}

.enviornment-toggler{
    margin-left: 5px;
    cursor: pointer;
}

.enviornment-toggler i{
    margin-right: 5px;
}

.react-confirm-alert-body h1{
    font-size: 30px;
}
.carousel-images-show{
    min-height: calc(2.55rem +8px);
    margin-top: 29px;
}

.carousel-images-show img{
    width: 100%;
    height: 120px;
    margin: 5px;
    cursor: pointer;
}
.MainIndex .card .percentages{
    margin-top: 2em;
}
.text-muted{
    font-size: 14px;
}
@media only screen and (max-width: 1646px) {
    .text-muted{
        font-size: 12.4px;
    }
}
@media only screen and (max-width: 1520px) {
    .text-muted{
        font-size: 12px;
    }
}

.custom-tab h3 {
    font-size: 20px;
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: center;
}

.customTitleMiddle>h3 {
    text-align: center;
}

.m-b-10 {
    margin-bottom: 10px;
}
.customTitleMiddle {
    margin: 30px 0;
}

.CustomMainIndexApp {
    margin: 50px 10px;
}

.custom-tab-row {
    position: fixed;
    width: 100%;
    top: 0;
    background: #f4f7fa;
    z-index: 100;
}


.call-logs-container {
    display: grid;
    width: 100%;
    grid-template-columns: 20px auto 140px;
    grid-template-rows: auto auto;
    grid-template-areas: 
    "statusTop name date"
    "statusDown number duration";

    grid-gap: 1px 5px;
    gap: 1px 5px;
    // border-bottom: 1px solid lightgrey;

}

.call-status-top {
    grid-area: statusTop;
    .fa-arrow-down{
        transform: rotate(45deg);
        position: relative;
        right: -2px;
        bottom: -4px;
    }

    .fa-arrow-up{
        transform: rotate(45deg);
        position: relative;
        right: -2px;
        bottom: -4px;
    }
}

.call-status-down {
    grid-area: statusDown;
    .fa-phone-alt{
        font-size: 18px;
        position: relative;
        left: -8px;
        top: -9px;
    }
    .fa-phone-slash{
        transform: rotate(90deg);
        font-size: 19px;
        position: relative;
        left: -11px;
        top: -9px;
    }
}

.call-name {
    grid-area: name;
}

.call-number {
    grid-area: number;
}

.call-date {
    grid-area: date;
    text-align: right;
}

.call-duration {
    grid-area: duration;
    text-align: right;
}

hr {
    margin-top: 8px;
    margin-bottom: 8px;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    width: 100%;
}

.cdr-custom-btns{
    border-radius: 44px;
    font-size: 12px;
    cursor: pointer;
    width: 80px;
    padding: 6px 6px;
}
.cdr-icons{
    width: 100%;
}
.cdr-icons div{
    text-align: center;
    text-align: -webkit-center;
}